/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateMachineType = /* GraphQL */ `subscription OnCreateMachineType(
  $filter: ModelSubscriptionMachineTypeFilterInput
) {
  onCreateMachineType(filter: $filter) {
    id
    machineTypeName
    productCode {
      nextToken
      __typename
    }
    department
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMachineTypeSubscriptionVariables,
  APITypes.OnCreateMachineTypeSubscription
>;
export const onUpdateMachineType = /* GraphQL */ `subscription OnUpdateMachineType(
  $filter: ModelSubscriptionMachineTypeFilterInput
) {
  onUpdateMachineType(filter: $filter) {
    id
    machineTypeName
    productCode {
      nextToken
      __typename
    }
    department
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMachineTypeSubscriptionVariables,
  APITypes.OnUpdateMachineTypeSubscription
>;
export const onDeleteMachineType = /* GraphQL */ `subscription OnDeleteMachineType(
  $filter: ModelSubscriptionMachineTypeFilterInput
) {
  onDeleteMachineType(filter: $filter) {
    id
    machineTypeName
    productCode {
      nextToken
      __typename
    }
    department
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMachineTypeSubscriptionVariables,
  APITypes.OnDeleteMachineTypeSubscription
>;
export const onCreateProductCode = /* GraphQL */ `subscription OnCreateProductCode(
  $filter: ModelSubscriptionProductCodeFilterInput
) {
  onCreateProductCode(filter: $filter) {
    id
    productCodeName
    errorCode {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    machineTypeProductCodeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateProductCodeSubscriptionVariables,
  APITypes.OnCreateProductCodeSubscription
>;
export const onUpdateProductCode = /* GraphQL */ `subscription OnUpdateProductCode(
  $filter: ModelSubscriptionProductCodeFilterInput
) {
  onUpdateProductCode(filter: $filter) {
    id
    productCodeName
    errorCode {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    machineTypeProductCodeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateProductCodeSubscriptionVariables,
  APITypes.OnUpdateProductCodeSubscription
>;
export const onDeleteProductCode = /* GraphQL */ `subscription OnDeleteProductCode(
  $filter: ModelSubscriptionProductCodeFilterInput
) {
  onDeleteProductCode(filter: $filter) {
    id
    productCodeName
    errorCode {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    machineTypeProductCodeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteProductCodeSubscriptionVariables,
  APITypes.OnDeleteProductCodeSubscription
>;
export const onCreateErrorCode = /* GraphQL */ `subscription OnCreateErrorCode($filter: ModelSubscriptionErrorCodeFilterInput) {
  onCreateErrorCode(filter: $filter) {
    id
    errorCode
    description
    remark
    createdAt
    updatedAt
    productCodeErrorCodeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateErrorCodeSubscriptionVariables,
  APITypes.OnCreateErrorCodeSubscription
>;
export const onUpdateErrorCode = /* GraphQL */ `subscription OnUpdateErrorCode($filter: ModelSubscriptionErrorCodeFilterInput) {
  onUpdateErrorCode(filter: $filter) {
    id
    errorCode
    description
    remark
    createdAt
    updatedAt
    productCodeErrorCodeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateErrorCodeSubscriptionVariables,
  APITypes.OnUpdateErrorCodeSubscription
>;
export const onDeleteErrorCode = /* GraphQL */ `subscription OnDeleteErrorCode($filter: ModelSubscriptionErrorCodeFilterInput) {
  onDeleteErrorCode(filter: $filter) {
    id
    errorCode
    description
    remark
    createdAt
    updatedAt
    productCodeErrorCodeId
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteErrorCodeSubscriptionVariables,
  APITypes.OnDeleteErrorCodeSubscription
>;
export const onCreateAlert = /* GraphQL */ `subscription OnCreateAlert($filter: ModelSubscriptionAlertFilterInput) {
  onCreateAlert(filter: $filter) {
    id
    machineType
    productCode
    serialNo
    errorCode
    userId
    department
    status
    description
    errorMessage
    remark
    occurred
    isSent
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAlertSubscriptionVariables,
  APITypes.OnCreateAlertSubscription
>;
export const onUpdateAlert = /* GraphQL */ `subscription OnUpdateAlert($filter: ModelSubscriptionAlertFilterInput) {
  onUpdateAlert(filter: $filter) {
    id
    machineType
    productCode
    serialNo
    errorCode
    userId
    department
    status
    description
    errorMessage
    remark
    occurred
    isSent
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAlertSubscriptionVariables,
  APITypes.OnUpdateAlertSubscription
>;
export const onDeleteAlert = /* GraphQL */ `subscription OnDeleteAlert($filter: ModelSubscriptionAlertFilterInput) {
  onDeleteAlert(filter: $filter) {
    id
    machineType
    productCode
    serialNo
    errorCode
    userId
    department
    status
    description
    errorMessage
    remark
    occurred
    isSent
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAlertSubscriptionVariables,
  APITypes.OnDeleteAlertSubscription
>;
