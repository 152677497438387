/** @jsxImportSource @emotion/react */
import React, { useState } from "react";
import Checkout from "../form/Checkout";
import { css } from "@emotion/react";

//useContextの初期値を設定。
export const ErrorContext = React.createContext(
  {} as {
    formData: ErrorForm;
    setFormData: React.Dispatch<React.SetStateAction<ErrorForm>>;
  }
);

export type ErrorForm = {
  machineTypeId: string | null;
  machineTypeName: string;
  productCodeId: string | null;
  productCode: string;
  errorCode: string;
  description: string;
  remark: string;
};

const MachineRegistPage: React.FC = () => {
  const [formData, setFormData] = useState<ErrorForm>({
    machineTypeId: null,
    machineTypeName: "",
    productCodeId: null,
    productCode: "",
    errorCode: "",
    description: "",
    remark: "",
  });
  return (
    <div css={mainStyle}>
      <ErrorContext.Provider value={{ formData, setFormData }}>
        <div css={title}>
        <h3>機器登録</h3>
        </div>
        <Checkout />
      </ErrorContext.Provider>
    </div>
  );
};

export default MachineRegistPage;

const mainStyle = css({
  width: "100%",
  hight:"calc(100vh - 64px)",
})
const title=css({
  hight: "36px",
})