/** @jsxImportSource @emotion/react */
import React, { useState, useContext, useEffect } from "react";

import { css } from "@emotion/react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Stack,
  Box,
  Container,
  Fade,
} from "@mui/material";

import CreateIcon from "@mui/icons-material/Create";
import { generateClient } from "@aws-amplify/api";
import { graphqlOperation } from '@aws-amplify/api-graphql';

import { createProductCode } from "../../graphql/mutations";

import {
  onCreateProductCode,
  onDeleteProductCode,
} from "../../graphql/subscriptions";

import { ListProductCodesQuery, ProductCode } from "../../API";

import { CreateProductCodeInput } from "../../API";

import {
  OnCreateProductCodeSubscriptionData,
  OnDeleteProductCodeSubscriptionData,
} from "../../APIcustom";

import { listProductCodes } from "../../graphql/queries";
import { ErrorContext } from "../product/MachineRegistPage";

import { UserContext } from "../../App";

type Product = {
  id: string;
  productCodeName: string;
};

interface Props {
  handleNext: () => void;
  handleBack: () => void;
}

export default function ProductCodeForm(props: Props) {
  const [createdProductCode, setCreatedProductCode] = useState<Product[]>(
    []
  );
  const [nextToken, setNextToken] = useState<string | null | undefined>(null);
  const [productCode, setProductCode] = useState<string>("");
  const { formData, setFormData } = useContext(ErrorContext);

  const [checked, setChecked] = React.useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const getProductCode = async () => {
    console.log(formData);

    const id = formData.machineTypeId ? formData.machineTypeId : "";
    const filter = {
      machineTypeProductCodeId: {
        eq: id,
      },
    };
    const client = generateClient();
    const result = await client.graphql(
      graphqlOperation(listProductCodes, {
        filter: filter,
      })
    );
    if ("data" in result && result.data) {
      console.log(result.data);
      const data = result.data as ListProductCodesQuery;
      if (data.listProductCodes) {
        setCreatedProductCode(data.listProductCodes.items as ProductCode[]);
        setNextToken(data.listProductCodes.nextToken);
      }
    }
  };

  const handleCreateProductCode = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!productCode) return;

    const data: CreateProductCodeInput = {
      productCodeName: productCode,
      machineTypeProductCodeId: formData.machineTypeId,
    };
    console.log("createProductCode");
    try {
      const client = generateClient();
      await client.graphql(graphqlOperation(createProductCode, { input: data }));
      setProductCode("");
      setChecked((prev) => !prev);
    } catch (err: any) {
      console.log(err);
    }
  };

  // subscribe = データ変更情報をリアルタイムで取得・反映
  const subscribeCreatedProductCode = () => {
    const apiClient = generateClient();
    const client = apiClient.graphql({query:onCreateProductCode})
    .subscribe({
      next: ({ data }) => {
        console.log(data)
        if (data.onCreateProductCode) {
          const createdProductCode: Product = {id:data.onCreateProductCode.id,productCodeName:data.onCreateProductCode.productCodeName};
          setCreatedProductCode((prev) => [createdProductCode, ...prev]);
        }
      },
      error: (error) => console.warn(error)
    });
  };

  const subscribeDeletedProductCode = () => {
    const apiClient = generateClient();
    const client = apiClient.graphql({query:onDeleteProductCode})
    .subscribe({
      next: ({ data }) => {
        console.log(data)
        if (data.onDeleteProductCode) {
          const deletedProductCodeId= data.onDeleteProductCode.id;
          setCreatedProductCode((prev) =>
            prev.filter((post) => post.id !== deletedProductCodeId)
          );
        }
      },
      error: (error) => console.warn(error)
    });
  };
  const onClickHandler = (machine: Product) => {
    setFormData({
      ...formData,
      productCode: machine.productCodeName,
      productCodeId: machine.id,
    });
    //console.log(formData);
    props.handleNext();
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
    console.log(checked);
  };

  useEffect(() => {
    getProductCode();
    subscribeCreatedProductCode();
    subscribeDeletedProductCode();
  }, []);
  return (
    <React.Fragment>
      <Container component="main" sx={{ mb: 1 }}>
        <Typography variant="h6" gutterBottom>
          機種タイプ選択
        </Typography>
        <Grid
          container
          spacing={3}
          sx={{ display: "contents", justifyContent: "flex-end" }}
        >
          <Stack spacing={2} direction="row">
            {createdProductCode?.map((machine: Product) => {
              return (
                <Button
                  variant="contained"
                  onClick={() => onClickHandler(machine)}
                  key={machine.id}
                >
                  {machine.productCodeName}
                </Button>
              );
            })}
          </Stack>
          <React.Fragment>
            <Button variant="outlined" onClick={handleChange} css={registSyle}>
              製品タイプ作成
            </Button>
            <Fade in={checked}>
              <form css={formStyle} noValidate onSubmit={handleCreateProductCode}>
                <TextField
                  placeholder="製品タイプ"
                  variant="outlined"
                  multiline
                  fullWidth
                  rows="1"
                  value={productCode}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setProductCode(e.target.value);
                  }}
                />
                <div css={submitBtnStyle}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="inherit"
                    disabled={!productCode}
                    startIcon={<CreateIcon />}
                    css={submitBtnStyle}
                  >
                    登録
                  </Button>
                </div>
              </form>
            </Fade>
          </React.Fragment>
        </Grid>
        <Container component="main" sx={{ mb: 1 }}>
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button onClick={props.handleBack} sx={{ mt: 1, ml: 1 }}>
              Back
            </Button>
          </Box>
        </Container>
      </Container>
    </React.Fragment>
  );
}

const formStyle = css({
  display: "flex",
  flexWrap: "wrap",
  width: 320,
});

const submitBtnStyle = css({
  marginTop: "10px",
  marginLeft: "auto",
});

const registSyle = css({
  marginTop: "50px",
});
