/** @jsxImportSource @emotion/react */
import React, { useState, useContext, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { css } from "@emotion/react";
import {
  AppBar,
  Container,
  Paper,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Toolbar,
} from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MachineTypeForm from "./MachineTypeForm";
import ErrorCodeForm from "./ErrorCodeForm";
import ProductCodeForm from "./ProductCodeForm";
import Review from "./Review";

import { UserContext } from "../../App";

const steps = ["機種選択", "製品コード選択", "エラー定義", "確認"];

const theme = createTheme();

export default function Checkout() {
  const { currentUser } = useContext(UserContext);
  const [activeStep, setActiveStep] = useState(0);

  const getStepContent = () => {
    switch (activeStep) {
      case 0:
        return <MachineTypeForm handleNext={handleNext} />;
      case 1:
        return (
          <ProductCodeForm handleNext={handleNext} handleBack={handleBack} />
        );
      case 2:
        return (
          <ErrorCodeForm handleNext={handleNext} handleBack={handleBack} />
        );
      case 3:
        return <Review handleNext={handleNext} handleBack={handleBack} />;
      default:
        throw new Error("Unknown step");
    }
  };

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
          height: "48px",
        }}
      >
        <Toolbar>
          <Typography variant="h6" color="inherit" noWrap>
            {currentUser?.attributes["custom:department"]}
          </Typography>
        </Toolbar>
      </AppBar>
      <Container component="main" css={mainStyle} sx={{ mb: 4, width: "80vh" }}>
        <Paper
          variant="outlined"
          sx={{ my: { xs:1, md:2 }, p: { xs: 1, md: 2 }, height: "auto" }}
        >
          <Typography component="h1" variant="h4" align="center">
            エラー定義作成
          </Typography>

          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography variant="h5" gutterBottom>
                登録完了
              </Typography>
              <Typography variant="subtitle1">登録しました。</Typography>
            </React.Fragment>
          ) : (
            <React.Fragment>{getStepContent()}</React.Fragment>
          )}
        </Paper>
      </Container>
    </ThemeProvider>
  );
}

const mainStyle = css({
  width: "100%",
})
