/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getMachineType = /* GraphQL */ `query GetMachineType($id: ID!) {
  getMachineType(id: $id) {
    id
    machineTypeName
    productCode {
      nextToken
      __typename
    }
    department
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMachineTypeQueryVariables,
  APITypes.GetMachineTypeQuery
>;
export const listMachineTypes = /* GraphQL */ `query ListMachineTypes(
  $filter: ModelMachineTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  listMachineTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      machineTypeName
      department
      userId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMachineTypesQueryVariables,
  APITypes.ListMachineTypesQuery
>;
export const getProductCode = /* GraphQL */ `query GetProductCode($id: ID!) {
  getProductCode(id: $id) {
    id
    productCodeName
    errorCode {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    machineTypeProductCodeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetProductCodeQueryVariables,
  APITypes.GetProductCodeQuery
>;
export const listProductCodes = /* GraphQL */ `query ListProductCodes(
  $filter: ModelProductCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      productCodeName
      createdAt
      updatedAt
      machineTypeProductCodeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListProductCodesQueryVariables,
  APITypes.ListProductCodesQuery
>;
export const getErrorCode = /* GraphQL */ `query GetErrorCode($id: ID!) {
  getErrorCode(id: $id) {
    id
    errorCode
    description
    remark
    createdAt
    updatedAt
    productCodeErrorCodeId
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetErrorCodeQueryVariables,
  APITypes.GetErrorCodeQuery
>;
export const listErrorCodes = /* GraphQL */ `query ListErrorCodes(
  $filter: ModelErrorCodeFilterInput
  $limit: Int
  $nextToken: String
) {
  listErrorCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      errorCode
      description
      remark
      createdAt
      updatedAt
      productCodeErrorCodeId
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListErrorCodesQueryVariables,
  APITypes.ListErrorCodesQuery
>;
export const getAlert = /* GraphQL */ `query GetAlert($id: ID!) {
  getAlert(id: $id) {
    id
    machineType
    productCode
    serialNo
    errorCode
    userId
    department
    status
    description
    errorMessage
    remark
    occurred
    isSent
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAlertQueryVariables, APITypes.GetAlertQuery>;
export const listAlerts = /* GraphQL */ `query ListAlerts(
  $filter: ModelAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlerts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      machineType
      productCode
      serialNo
      errorCode
      userId
      department
      status
      description
      errorMessage
      remark
      occurred
      isSent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAlertsQueryVariables,
  APITypes.ListAlertsQuery
>;
export const machineTypesByUserId = /* GraphQL */ `query MachineTypesByUserId(
  $userId: String!
  $sortDirection: ModelSortDirection
  $filter: ModelMachineTypeFilterInput
  $limit: Int
  $nextToken: String
) {
  machineTypesByUserId(
    userId: $userId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      machineTypeName
      department
      userId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.MachineTypesByUserIdQueryVariables,
  APITypes.MachineTypesByUserIdQuery
>;
export const alertsByMachineTypeAndOccurred = /* GraphQL */ `query AlertsByMachineTypeAndOccurred(
  $machineType: String!
  $occurred: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  alertsByMachineTypeAndOccurred(
    machineType: $machineType
    occurred: $occurred
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      machineType
      productCode
      serialNo
      errorCode
      userId
      department
      status
      description
      errorMessage
      remark
      occurred
      isSent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AlertsByMachineTypeAndOccurredQueryVariables,
  APITypes.AlertsByMachineTypeAndOccurredQuery
>;
export const alertsByProductCodeAndOccurred = /* GraphQL */ `query AlertsByProductCodeAndOccurred(
  $productCode: String!
  $occurred: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  alertsByProductCodeAndOccurred(
    productCode: $productCode
    occurred: $occurred
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      machineType
      productCode
      serialNo
      errorCode
      userId
      department
      status
      description
      errorMessage
      remark
      occurred
      isSent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AlertsByProductCodeAndOccurredQueryVariables,
  APITypes.AlertsByProductCodeAndOccurredQuery
>;
export const alertsBySerialNoAndOccurred = /* GraphQL */ `query AlertsBySerialNoAndOccurred(
  $serialNo: String!
  $occurred: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  alertsBySerialNoAndOccurred(
    serialNo: $serialNo
    occurred: $occurred
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      machineType
      productCode
      serialNo
      errorCode
      userId
      department
      status
      description
      errorMessage
      remark
      occurred
      isSent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AlertsBySerialNoAndOccurredQueryVariables,
  APITypes.AlertsBySerialNoAndOccurredQuery
>;
export const alertsByErrorCodeAndOccurred = /* GraphQL */ `query AlertsByErrorCodeAndOccurred(
  $errorCode: String!
  $occurred: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  alertsByErrorCodeAndOccurred(
    errorCode: $errorCode
    occurred: $occurred
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      machineType
      productCode
      serialNo
      errorCode
      userId
      department
      status
      description
      errorMessage
      remark
      occurred
      isSent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AlertsByErrorCodeAndOccurredQueryVariables,
  APITypes.AlertsByErrorCodeAndOccurredQuery
>;
export const alertsByDepartmentAndOccurred = /* GraphQL */ `query AlertsByDepartmentAndOccurred(
  $department: String!
  $occurred: ModelStringKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelAlertFilterInput
  $limit: Int
  $nextToken: String
) {
  alertsByDepartmentAndOccurred(
    department: $department
    occurred: $occurred
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      machineType
      productCode
      serialNo
      errorCode
      userId
      department
      status
      description
      errorMessage
      remark
      occurred
      isSent
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.AlertsByDepartmentAndOccurredQueryVariables,
  APITypes.AlertsByDepartmentAndOccurredQuery
>;
