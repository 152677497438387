/** @jsxImportSource @emotion/react */
import React from "react";
import { css } from "@emotion/react";
import DashboardContent from "../dashboard/Dashboard";

const ProductPage: React.FC = () => {
  return (
    <div css={style}>
      <h3>プロダクトページ(これはハリボテ)</h3>
      <DashboardContent />
    </div>
  );
};

export default ProductPage;

const style = css`
    width: 100%;
`;