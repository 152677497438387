/** @jsxImportSource @emotion/react */
import React from "react";
import RegistedCode from "./RegistedCode";
import AppBar from "@mui/material/AppBar";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme();

const RegistedCodePage: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <h3>エラーリスト</h3>
      <RegistedCode />
    </ThemeProvider>
  );
};

export default RegistedCodePage;
