import React, { useState, useEffect, createContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { Amplify} from "aws-amplify";
import { Hub } from 'aws-amplify/utils'
import { signUp,getCurrentUser, AuthUser,fetchUserAttributes } from 'aws-amplify/auth';
import {
  fetchAuthSession,
  signInWithRedirect
} from 'aws-amplify/auth';
import { ThemeProvider, Loader, Text } from "@aws-amplify/ui-react";

import "@aws-amplify/ui-react/styles.css";

import Wrapper from "./components/layouts/Wrapper";
import ProductPage from "./components/product/ProductPage";
import MachineRegistPage from "./components/product/MachineRegistPage";
import RegistedCodePage from "./components/product/RegistedCodePage";

import "./App.css";
import awsconfig from "./aws-exports";
import { User } from "./APIcustom";

export const UserContext = createContext(
  {} as {
    currentUser: User | null;
    setCurrentUser: React.Dispatch<React.SetStateAction<User | null>>;
  }
);

Amplify.configure(awsconfig);

const App = () => {
  //const [authInProgress, setAuthInProgress] = useState(true);
  const [authInProgress, setAuthInProgress] = useState(false);
  const [currentUser, setCurrentUser] = useState<User | null>(null);

  useEffect(() => {
    const unsubscribe = Hub.listen("auth", (data) => {
      console.log("Hub");
      const { payload } = data;
      switch (payload.event) {
        case "signedIn":
          console.log("signin" + JSON.stringify(data));
          setAuthInProgress(false);
          break;
        case "signedOut":
          setCurrentUser(null);
          setAuthInProgress(false);
          break;
        default:
      }
    });
    parseUser()
      .then((user)=>{
        console.log("user:"+JSON.stringify(user))
        setCurrentUser(user)})
      .catch(() => {
        if(!authInProgress){
          userLogin();
        }
        setAuthInProgress(true);
      });

    return unsubscribe;
  }, []);

  const parseUser = async ()=>{
    const {
      username,
      userId: id
    } = await getCurrentUser();

    const attributes = await fetchUserAttributes();
    console.log(JSON.stringify(attributes))
    
    const user:User ={id:id,
      username:username,
      attributes:{
        email:attributes["email"] as string,
        "custom:department":attributes["custom:department"] as string,
        family_name:attributes["family_name"] as string,
        given_name:attributes["given_name"] as string
      }}

    return user;
  }

  function userLogin() {
    console.log("Starting Login");

    signInWithRedirect({
      provider: {
        custom: "AzureAD"
      }
    })
    .then((cred) => {
      // If success, you will get the AWS credentials
      console.log("Cred: " + cred);
    })
    .then((user) => {
      // If success, the user object you passed in Auth.federatedSignIn
      console.log("User: " + user);
    })
    .catch((e) => {
      console.log("ERROR: " + e);
    });
  }
  
  return (
    <ThemeProvider>
      {authInProgress ? (
        <header className="App-header">
          <Loader style={{ width: "5rem", height: "5rem" }} />
          <Text color="white">Signing in ...</Text>
        </header>
      ) : (
        <Router>
          <UserContext.Provider value={{ currentUser, setCurrentUser }}>
            <Wrapper>
              <Routes>
                <Route path="/" element={<ProductPage />} />
                <Route path="/products" element={<ProductPage />} />
                <Route path="/machinereg" element={<MachineRegistPage />} />
                <Route path="/registed" element={<RegistedCodePage />} />
              </Routes>
            </Wrapper>
          </UserContext.Provider>
        </Router>
      )}
    </ThemeProvider>
  );
};

export default App;
