/** @jsxImportSource @emotion/react */
import { useState, useContext, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { SimpleTreeView } from '@mui/x-tree-view/SimpleTreeView';
import { TreeItem } from '@mui/x-tree-view/TreeItem';
import { css } from "@emotion/react";
import { ExpandMore, ChevronRight, Delete } from "@mui/icons-material";
import AddBoxIcon from '@mui/icons-material/AddBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import {
  Paper,
  Container,
  Typography,
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Modal,
  Box,
} from "@mui/material";
//import Grid from "@mui/material/Unstable_Grid2";
import Grid from '@mui/material/Grid2';

//import { getMachineTree } from "../../graphql/queries";
import { getMachineTree } from "../../graphql/custom-queries";
import { UserContext } from "../../App";
import { generateClient } from "@aws-amplify/api";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

type MachineType = {
  id: string;
  machineTypeName: string;
  productCode?: { items: Array<ProductCode> };
};

type ProductCode = {
  id: string;
  productCodeName: string;
  errorCode?: { items: Array<ErrorCode> };
};

type ErrorCode = {
  id: string;
  description: string;
  remark: string;
  errorCode: string;
};

export default function RegistedCode() {
  const { currentUser } = useContext(UserContext);
  const [machineItem, setMachineItem] = useState<MachineType[]>([]);
  const [open, setOpen] = useState(false);
  const [detailOpen, setDetailOpen] = useState(false);
  const [deleteID, setDeleteID] = useState<string | null>(null);
  const [choosedErr, setChoosedErr] = useState<ErrorCode | null>(null);

  const getMachine = async () => {
    const client = generateClient();
    const result = await client.graphql({
      query: getMachineTree,
      variables: {
        department: currentUser
          ? currentUser.attributes["custom:department"]
          : "",
        sortDirection: "DESC",
        //limit: 5, // 一度のリクエストで取得可能な件数（この辺はお好みで）
      },
    });
    //console.log(result);
    if ("data" in result && result.data) {
      if (result.data.listMachineTypes) {
        setMachineItem(result.data["listMachineTypes"].items as MachineType[]);
      }
    }
  };
  useEffect(() => {
    getMachine();
  }, []);

  const machineTree = (machine: MachineType) => {
    console.log("machine:" + JSON.stringify(machine));
    return (
      <TreeItem
        key={machine.id}
        itemId={machine.id}
        label={machine.machineTypeName}
      >
        {Array.isArray(machine.productCode?.items)
          ? machine.productCode?.items.map((code) => productTree(code))
          : null}
      </TreeItem>
    );
  };
  const productTree = (code: ProductCode) => {
    console.log("productCode:" + JSON.stringify(code));
    return (
      <TreeItem key={code.id} itemId={code.id} label={code.productCodeName}>
        {Array.isArray(code.errorCode?.items)
          ? code.errorCode?.items.map((err) => errTree(err))
          : null}
      </TreeItem>
    );
  };
  const errTree = (err: ErrorCode) => {
    console.log("errorCode:" + JSON.stringify(err));
    return (
      <TreeItem
        key={err.id}
        itemId={err.id}
        label={
          <Typography onClick={() => handleClickDetailOpen(err)}>
            {err.errorCode}
          </Typography>
        }
        //endIcon={<Delete onClick={() => handleClickOpen(err.id)} />}
        //onClick={() => handleClickDetailOpen(err)}
      />
    );
  };

  const deleteDialog = () => {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"削除してよいですか？(ハリボテ)"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {deleteID}を削除します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>cancel</Button>
          <Button onClick={handleClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  const handleClickOpen = (id: string) => {
    console.log(id);
    setDeleteID(id);
    setOpen(true);
  };

  const detailDialog = () => {
    return (
      <Modal
        open={detailOpen}
        onClose={handleDetailClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={style}>
          <h2 id="child-modal-title">{choosedErr?.errorCode}</h2>
          <Grid container spacing={2}>
            <Grid>
              <Item css={itemStyle}>
                <h3>description</h3>
                <p>{choosedErr?.description}</p>
              </Item>
            </Grid>
            <Grid>
              <Item css={itemStyle}>
                <h3>remark</h3>
                <p>{choosedErr?.remark}</p>
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    );
  };
  const handleClickDetailOpen = (err: ErrorCode) => {
    setChoosedErr(err);
    setDetailOpen(true);
  };

  const handleClose = () => {
    setDeleteID(null);
    setOpen(false);
  };
  const handleDetailClose = () => {
    setChoosedErr(null);
    setDetailOpen(false);
  };

  function CloseSquare(props: SvgIconProps) {
    return (
      <SvgIcon
        className="close"
        fontSize="inherit"
        style={{ width: 14, height: 14 }}
        {...props}
      >
        {/* tslint:disable-next-line: max-line-length */ }
        <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
      </SvgIcon>
    );
  }

  return (
    <Container component="main" sx={{ mb: 1, width: "100vh" }}>
      <Paper
        variant="outlined"
        sx={{ my: { xs: 1, md: 1 }, p: { xs: 1, md: 1 }, height: '100%'}}
      >
        <Typography component="h1" variant="h5">
          登録アラート一覧(機器 &gt; 製品コード &gt; エラーコード)
        </Typography>
        <SimpleTreeView
          defaultExpandedItems={['grid']}
          sx={{
            height: "auto",
            flexGrow: 1,
            overflowY: "auto",
            paddingTop: "30px",
          }}
          slots={{
            expandIcon: AddBoxIcon,
            collapseIcon: IndeterminateCheckBoxIcon,
            endIcon: CloseSquare,
          }}
        >
          {machineItem.map((machine) => machineTree(machine))}
        </SimpleTreeView>
        <Alert severity="info">
          エラー以外は消せると怖いので、依頼ベースでポータルで削除します
        </Alert>
      </Paper>
      {deleteDialog()}
      {detailDialog()}
    </Container>
  );
}

const itemStyle = css`
  word-break: break-all;
`;