import React, { useState, useContext, useEffect } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Box, Container, Grid, Paper, Stack, Button } from "@mui/material";
import Alerts from "./Alerts";
import { listAlerts, listMachineTypes } from "../../graphql/queries";
import { ListMachineTypesQuery, MachineType } from "../../API";
import { UserContext } from "../../App";
import { generateClient } from "@aws-amplify/api";

const mdTheme = createTheme();

function DashboardContent() {
  const { currentUser } = useContext(UserContext);
  const [machineTypeList, setMachineTypeList] = useState<MachineType[]>([]);
  const [selectedMachine,setSelectedMahcine]=useState<MachineType>(machineTypeList[0]);
  const getMachine = async () => {
    const client = generateClient();
    const user = currentUser ? /*currentUser.id*/ "" : "";
    const variables = {
      filter: {
        userId: {contains:user},
      },
      sortDirection: "DESC",
      limit: 5, // 一度のリクエストで取得可能な件数（この辺はお好みで）
    };
    const result = await client.graphql({
      query: listMachineTypes,
      variables: variables,
    });
    if ("data" in result && result.data) {
      console.log(result.data);
      const data = result.data as ListMachineTypesQuery;
      if (data.listMachineTypes) {
        setMachineTypeList(data.listMachineTypes.items as MachineType[]);
        console.log(data.listMachineTypes.items);
      }
    }
  };

  useEffect(() => {
    getMachine();
  }, []);

  const onClickHandler = (machine: MachineType) => {
    setSelectedMahcine(machine)
  };

  const chooseMachine = () => {
    return machineTypeList.map((machine) => {
      return (
        <Button
          variant="contained"
          onClick={() => onClickHandler(machine)}
          key={machine.id}
        >
          {machine.machineTypeName}
        </Button>
      );
    });
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            //height: "80vh",
            overflow: "auto",
          }}
        >
          <Grid
            container
            spacing={3}
            sx={{ display: "contents", justifyContent: "flex-end" }}
          >
            <Stack spacing={2} direction="row">
              {chooseMachine()}
            </Stack>
          </Grid>
          <Container maxWidth="xl" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Alerts machineType={selectedMachine}/>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default function Dashboard() {
  return <DashboardContent />
}
