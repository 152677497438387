/** @jsxImportSource @emotion/react */
import React, { useState, useContext, useEffect } from "react";
import { css } from "@emotion/react";
import Link from "@mui/material/Link";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import Title from "./Title";
import Calendar from "./Calender";

import { listAlerts } from "../../graphql/queries";
import { generateClient } from "@aws-amplify/api";
import { ListAlertsQuery, Alert,MachineType } from "../../API";
import { UserContext } from "../../App";

// Generate Order Data
function createData(
  id: number,
  date: string,
  Serial: string,
  machineType: string,
  productType: string,
  errorCode: string
) {
  return { id, date, Serial, machineType, productType, errorCode };
}

const rows = [
  createData(0, "1/8", "5903759834", "AW5600", "DPS", "E-22"),
  createData(1, "1/8", "5903743234", "AW5600", "DPS", "E-22"),
  createData(2, "1/8", "839454294", "AW5600", "DUKE", "E-8"),
  createData(3, "1/8", "352647272", "AW5600", "DPS", "E-22"),
  createData(4, "1/8", "5903759834", "AW5600", "DPS", "E-22"),
];

function preventDefault(event: React.MouseEvent) {
  event.preventDefault();
}


interface Props {
  machineType: MachineType;
}

const Alerts: React.FC<Props> = ({machineType}) => {
  const { currentUser } = useContext(UserContext);
  const [alertList, setAlertList] = useState<Alert[]>([]);
  
  const getAlerts = async () => {
    const client = generateClient();
    const dep = currentUser ? currentUser.attributes["custom:department"] : "";
    const variables = {
      filter: {
        department: {contains:dep},
      },
      sortDirection: "DESC",
      limit: 5, // 一度のリクエストで取得可能な件数（この辺はお好みで）
    };
    const result = await client.graphql({
      query: listAlerts,
      variables: variables
    });
    if ("data" in result && result.data) {
      console.log(result.data);
      const data = result.data as ListAlertsQuery;
      if (data.listAlerts) {
        setAlertList(data.listAlerts.items as Alert[]);
        console.log(data.listAlerts.items);
      }
    }
  };
  useEffect(() => {
    getAlerts();
  }, []);

  return (
    <React.Fragment>
      <Title>Recent Alerts</Title>
      <Calendar />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Serial No.</TableCell>
            <TableCell>MachineType</TableCell>
            <TableCell>ProductCode</TableCell>
            <TableCell>ErrCode</TableCell>
            <TableCell>Sent</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {alertList.map((alert) => (
            <TableRow key={alert.id}>
              <TableCell>{alert.occurred}</TableCell>
              <TableCell>{alert.serialNo}</TableCell>
              <TableCell>{alert.machineType}</TableCell>
              <TableCell>{alert.productCode}</TableCell>
              <TableCell>{alert.errorCode}</TableCell>
              <TableCell>{alert.isSent? "⚪︎":""}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}

export default Alerts;