/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";

import { Typography, Container, Grid, Box, Button } from "@mui/material";
import { ErrorContext } from "../product/MachineRegistPage";

import { generateClient} from "@aws-amplify/api";
import { graphqlOperation } from '@aws-amplify/api-graphql';

import { createErrorCode } from "../../graphql/mutations";
import { CreateErrorCodeInput } from "../../API";

interface Props {
  handleNext: () => void;
  handleBack: () => void;
}
export default function Review(props: Props) {
  const { formData, setFormData } = useContext(ErrorContext);

  const handleCreateErrCode = async () => {
    const data: CreateErrorCodeInput = {
      errorCode: formData.errorCode,
      description: formData.description,
      remark: formData.remark,
      productCodeErrorCodeId: formData.productCodeId,
    };
    //console.log(formData);
    try {
      const client = generateClient();
      await client.graphql(graphqlOperation(createErrorCode, { input: data }));
    } catch (err: any) {
      console.log(err);
    }
    props.handleNext();
  };
  return (
    <React.Fragment>
      <Container component="main" sx={{ mb: 1 }}>
        <Grid item container direction="column" xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                機種
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{formData.machineTypeName}</Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant="h6" gutterBottom>
                機器タイプ
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography gutterBottom>{formData.productCode}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item container direction="column" xs={12}>
            <Typography variant="h6" gutterBottom sx={{ mt: 1 }}>
              ErrorCode : {formData.errorCode}
            </Typography>
            <Grid container>
              <React.Fragment key={formData.errorCode}>
                <Grid item xs={6}>
                  <Typography gutterBottom>{formData.description}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography gutterBottom>{formData.remark}</Typography>
                </Grid>
              </React.Fragment>
            </Grid>
          </Grid>
          <Container component="main" maxWidth="sm" sx={{ mb: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={props.handleBack} sx={{ mt: 1, ml: 1 }}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={handleCreateErrCode}
                sx={{ mt: 1, ml: 1 }}
              >
                OK
              </Button>
            </Box>
          </Container>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
