/** @jsxImportSource @emotion/react */
import React, { useState, useContext } from "react";

import {
  Typography,
  Grid,
  TextField,
  Box,
  Container,
  Button,
} from "@mui/material";
import { ErrorContext } from "../product/MachineRegistPage";

interface Props {
  handleNext: () => void;
  handleBack: () => void;
}

export default function ErrorCodeForm(props: Props) {
  const { formData, setFormData } = useContext(ErrorContext);
  const [errorCode, setErrorCode] = useState(formData.errorCode);
  const [description, setDescription] = useState(formData.description);
  const [remark, setRemark] = useState(formData.remark);

  const onClickHandler = () => {
    setFormData({
      ...formData,
      errorCode: errorCode,
      description: description,
      remark: remark,
    });
    console.log(formData);
    props.handleNext();
  };
  return (
    <React.Fragment>
      <Container component="main" sx={{ mb: 1 }}>
        <Typography variant="h6" gutterBottom>
          エラー定義
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              id="errorCode"
              label="ErrorCode"
              fullWidth
              variant="standard"
              value={errorCode}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setErrorCode(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              id="description"
              label="Description"
              multiline
              rows="3"
              fullWidth
              variant="outlined"
              value={description}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setDescription(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              multiline
              id="remark"
              label="Remark"
              rows="3"
              fullWidth
              variant="outlined"
              value={remark}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setRemark(e.target.value);
              }}
            />
          </Grid>
          <Container component="main" maxWidth="sm" sx={{ mb:1 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
              <Button onClick={props.handleBack} sx={{ mt:1, ml: 1 }}>
                Back
              </Button>
              <Button
                variant="contained"
                onClick={onClickHandler}
                disabled={!errorCode || !description || !remark}
                sx={{ mt: 1, ml: 1 }}
              >
                Next
              </Button>
            </Box>
          </Container>
        </Grid>
      </Container>
    </React.Fragment>
  );
}
