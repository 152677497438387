/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createMachineType = /* GraphQL */ `mutation CreateMachineType(
  $input: CreateMachineTypeInput!
  $condition: ModelMachineTypeConditionInput
) {
  createMachineType(input: $input, condition: $condition) {
    id
    machineTypeName
    productCode {
      nextToken
      __typename
    }
    department
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMachineTypeMutationVariables,
  APITypes.CreateMachineTypeMutation
>;
export const updateMachineType = /* GraphQL */ `mutation UpdateMachineType(
  $input: UpdateMachineTypeInput!
  $condition: ModelMachineTypeConditionInput
) {
  updateMachineType(input: $input, condition: $condition) {
    id
    machineTypeName
    productCode {
      nextToken
      __typename
    }
    department
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMachineTypeMutationVariables,
  APITypes.UpdateMachineTypeMutation
>;
export const deleteMachineType = /* GraphQL */ `mutation DeleteMachineType(
  $input: DeleteMachineTypeInput!
  $condition: ModelMachineTypeConditionInput
) {
  deleteMachineType(input: $input, condition: $condition) {
    id
    machineTypeName
    productCode {
      nextToken
      __typename
    }
    department
    userId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMachineTypeMutationVariables,
  APITypes.DeleteMachineTypeMutation
>;
export const createProductCode = /* GraphQL */ `mutation CreateProductCode(
  $input: CreateProductCodeInput!
  $condition: ModelProductCodeConditionInput
) {
  createProductCode(input: $input, condition: $condition) {
    id
    productCodeName
    errorCode {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    machineTypeProductCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateProductCodeMutationVariables,
  APITypes.CreateProductCodeMutation
>;
export const updateProductCode = /* GraphQL */ `mutation UpdateProductCode(
  $input: UpdateProductCodeInput!
  $condition: ModelProductCodeConditionInput
) {
  updateProductCode(input: $input, condition: $condition) {
    id
    productCodeName
    errorCode {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    machineTypeProductCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateProductCodeMutationVariables,
  APITypes.UpdateProductCodeMutation
>;
export const deleteProductCode = /* GraphQL */ `mutation DeleteProductCode(
  $input: DeleteProductCodeInput!
  $condition: ModelProductCodeConditionInput
) {
  deleteProductCode(input: $input, condition: $condition) {
    id
    productCodeName
    errorCode {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    machineTypeProductCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteProductCodeMutationVariables,
  APITypes.DeleteProductCodeMutation
>;
export const createErrorCode = /* GraphQL */ `mutation CreateErrorCode(
  $input: CreateErrorCodeInput!
  $condition: ModelErrorCodeConditionInput
) {
  createErrorCode(input: $input, condition: $condition) {
    id
    errorCode
    description
    remark
    createdAt
    updatedAt
    productCodeErrorCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateErrorCodeMutationVariables,
  APITypes.CreateErrorCodeMutation
>;
export const updateErrorCode = /* GraphQL */ `mutation UpdateErrorCode(
  $input: UpdateErrorCodeInput!
  $condition: ModelErrorCodeConditionInput
) {
  updateErrorCode(input: $input, condition: $condition) {
    id
    errorCode
    description
    remark
    createdAt
    updatedAt
    productCodeErrorCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateErrorCodeMutationVariables,
  APITypes.UpdateErrorCodeMutation
>;
export const deleteErrorCode = /* GraphQL */ `mutation DeleteErrorCode(
  $input: DeleteErrorCodeInput!
  $condition: ModelErrorCodeConditionInput
) {
  deleteErrorCode(input: $input, condition: $condition) {
    id
    errorCode
    description
    remark
    createdAt
    updatedAt
    productCodeErrorCodeId
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteErrorCodeMutationVariables,
  APITypes.DeleteErrorCodeMutation
>;
export const createAlert = /* GraphQL */ `mutation CreateAlert(
  $input: CreateAlertInput!
  $condition: ModelAlertConditionInput
) {
  createAlert(input: $input, condition: $condition) {
    id
    machineType
    productCode
    serialNo
    errorCode
    userId
    department
    status
    description
    errorMessage
    remark
    occurred
    isSent
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAlertMutationVariables,
  APITypes.CreateAlertMutation
>;
export const updateAlert = /* GraphQL */ `mutation UpdateAlert(
  $input: UpdateAlertInput!
  $condition: ModelAlertConditionInput
) {
  updateAlert(input: $input, condition: $condition) {
    id
    machineType
    productCode
    serialNo
    errorCode
    userId
    department
    status
    description
    errorMessage
    remark
    occurred
    isSent
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAlertMutationVariables,
  APITypes.UpdateAlertMutation
>;
export const deleteAlert = /* GraphQL */ `mutation DeleteAlert(
  $input: DeleteAlertInput!
  $condition: ModelAlertConditionInput
) {
  deleteAlert(input: $input, condition: $condition) {
    id
    machineType
    productCode
    serialNo
    errorCode
    userId
    department
    status
    description
    errorMessage
    remark
    occurred
    isSent
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAlertMutationVariables,
  APITypes.DeleteAlertMutation
>;
