import * as React from "react";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ja from "date-fns/locale/ja";
import { Box} from "@mui/material";

export default function Calendar() {
  const [dateStart, setDateStart] = React.useState<Dayjs | null>(null)
  const [dateEnd, setDateEnd] = React.useState<Dayjs | null>(null)
  const [openEndDatePicker, setOpenEndDatePicker] = React.useState(false)

  // 開始日が選択された後に終了日のカレンダーを開く
  const handleChangeStart = (newDate: Dayjs | null) => {
    setDateStart(newDate)

    // 終了日よりあとの開始日が選択されたら、終了日をリセットする
    if (newDate && dateEnd && dayjs(dateEnd).isBefore(newDate)) {
      setDateEnd(null)
    }

    // 開始日が選択されたときに終了日のカレンダーを開く
    setOpenEndDatePicker(true)
  }

  // 終了日が選択された後にカレンダーを閉じる
  const handleChangeEnd = (newDate: Dayjs | null) => {
    setDateEnd(newDate)
  }

  // 終了日のカレンダーを開いたときにopenEndDatePickerをtrueにする
  const handleOpenEndDatePicker = () => {
    setOpenEndDatePicker(true)
  }

  return (
    <React.Fragment>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
        <Box sx={{ m: 2, width: "25ch" }}>
        <DatePicker
          label="開始日"
          value={dateStart}
          onChange={handleChangeStart}
          format="YYYY/MM/DD"
        />
        <DatePicker
          label="終了日"
          value={dateEnd}
          onChange={handleChangeEnd}
          format="YYYY/MM/DD"
          minDate={dateStart || undefined}
          open={openEndDatePicker}
          onOpen={handleOpenEndDatePicker}
          onClose={() => setOpenEndDatePicker(false)}
        />

        </Box>
      </LocalizationProvider>
    </React.Fragment>
  );
}
