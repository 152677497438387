/** @jsxImportSource @emotion/react */
import React, { useState, useContext, useEffect } from "react";

import { css } from "@emotion/react";
import {
  Grid,
  Typography,
  TextField,
  Button,
  Stack,
  Container,
  Fade,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";

import { generateClient } from "@aws-amplify/api";

import { createMachineType } from "../../graphql/mutations";
import { ErrorContext } from "../product/MachineRegistPage";

import {
  onCreateMachineType,
  onDeleteMachineType,
} from "../../graphql/subscriptions";

import { ListMachineTypesQuery, MachineType } from "../../API";

import { CreateMachineTypeInput } from "../../API";

import {
  OnCreateMachineTypeSubscriptionData,
  OnDeleteMachineTypeSubscriptionData,
} from "../../APIcustom";

import { listMachineTypes } from "../../graphql/queries";

import { UserContext } from "../../App";
const formStyle = css({
  display: "flex",
  flexWrap: "wrap",
  width: 320,
});

interface Props {
  handleNext: () => void;
}

type Machine = {
  id: string;
  machineTypeName: string;
};

export default function MachineDefForm(props: Props) {
  const { currentUser } = useContext(UserContext);
  const [createdMachineType, setCreatedMachineType] = useState<Machine[]>(
    []
  );
  const [nextToken, setNextToken] = useState<string | null | undefined>(null);
  const [machineType, setMachineType] = useState<string>("");

  const [checked, setChecked] = React.useState(false);

  const [loading, setLoading] = useState<boolean>(false);

  const { formData, setFormData } = useContext(ErrorContext);
  const client = generateClient()

  const getMachine = async () => {
    const user = currentUser ? /*currentUser.id*/ "" : "";
    const variables = {
      filter: {
        userId: {contains:user},
      },
      sortDirection: "DESC",
      limit: 5, // 一度のリクエストで取得可能な件数（この辺はお好みで）
    };
    const result = await client.graphql({
      query: listMachineTypes,
      variables: variables
    });
    if ("data" in result && result.data) {
      console.log(result.data);
      const data = result.data as ListMachineTypesQuery;
      if (data.listMachineTypes) {
        setCreatedMachineType(data.listMachineTypes.items as MachineType[]);
        setNextToken(data.listMachineTypes.nextToken);
        //console.log(data.listMachineTypes.items);
      }
    }
  };

  const handleCreateMachine = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!machineType) return;
    const data: CreateMachineTypeInput = {
      userId: currentUser ? currentUser.username : "",
      department: currentUser
        ? currentUser.attributes["custom:department"]
        : "",
      machineTypeName: machineType,
    };
    console.log("createMachine");
    try {
      await client.graphql({
        query: createMachineType,
        variables: { input: data },
      });
      setMachineType("");
      setChecked((prev) => !prev);
    } catch (err: any) {
      console.log(err);
    }
  };

  // subscribe = データ変更情報をリアルタイムで取得・反映
  const subscribeCreatedMachine = () => {
    //ここで何個か作ると個数分できるかも
    const subClient = client.graphql({
      query: onCreateMachineType,
    }).subscribe({
      next: ({ data }) => {
        console.log(data)
        if (data.onCreateMachineType) {
          const createdMachineType: Machine = {id:data.onCreateMachineType.id,machineTypeName:data.onCreateMachineType.machineTypeName};
          setCreatedMachineType((prev) => [createdMachineType, ...prev]);
        }
      },
      error: (error) => console.warn(error)
    });
  };

  const subscribeDeletedMachine = () => {
    const subClient = client.graphql({
      query: onDeleteMachineType,
    }).subscribe({
      next: ({ data }) => {
        console.log(data)
        if (data.onDeleteMachineType) {
          const deletedMachineTypeId = data.onDeleteMachineType.id;
          setCreatedMachineType((prev) =>
            prev.filter((post) => post.id !== deletedMachineTypeId)
          );
        }
      },
      error: (error) => console.warn(error)
    });
  };

  const onClickHandler = (machine: Machine) => {
    setFormData({
      ...formData,
      machineTypeName: machine.machineTypeName,
      machineTypeId: machine.id,
    });
    props.handleNext();
  };

  const handleChange = () => {
    setChecked((prev) => !prev);
    console.log(checked);
  };

  useEffect(() => {
    console.log("effect");
    getMachine();
    subscribeCreatedMachine();
    subscribeDeletedMachine();
  }, []);
  return (
    <React.Fragment>
      <Container component="main" sx={{ mb: 1 }}>
        <Typography variant="h6" gutterBottom>
          機器選択
        </Typography>
        <Grid
          container
          spacing={3}
          sx={{ display: "contents", justifyContent: "flex-end" }}
        >
          <Stack spacing={2} direction="row">
            {createdMachineType?.map((machine: Machine) => {
              return (
                <Button
                  variant="contained"
                  onClick={() => onClickHandler(machine)}
                  key={machine.id}
                >
                  {machine.machineTypeName}
                </Button>
              );
            })}
          </Stack>
          <React.Fragment>
            <Button variant="outlined" onClick={handleChange} css={registSyle}>
              機器タイプ作成
            </Button>
            <Fade in={checked}>
              <form css={formStyle} noValidate onSubmit={handleCreateMachine}>
                <TextField
                  placeholder="機器タイプ"
                  variant="outlined"
                  fullWidth
                  rows="1"
                  value={machineType}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setMachineType(e.target.value);
                  }}
                />
                <div css={submitBtnStyle}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="small"
                    color="inherit"
                    disabled={!machineType}
                    startIcon={<CreateIcon />}
                    css={submitBtnStyle}
                  >
                    登録
                  </Button>
                </div>
              </form>
            </Fade>
          </React.Fragment>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

const submitBtnStyle = css({
  marginTop: "10px",
  marginLeft: "auto",
});

const registSyle = css({
  marginTop: "50px",
});
