export const getMachineTree = /* GraphQL */ `
  query GetMachineTree(
    $filter: ModelMachineTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMachineTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        machineTypeName
        productCode {
          items {
            id
            productCodeName
            errorCode {
              items {
                id
                description
                errorCode
                remark
              }
            }
          }
        }
      }
    }
  }
`;
