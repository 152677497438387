/** @jsxImportSource @emotion/react */
import React, { useContext } from "react";
import { styled } from "@mui/material/styles";
import { Link } from "react-router-dom";

import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  List,
  Container,
  Grid,
} from "@mui/material";
import Drawer, { DrawerProps } from '@mui/material/Drawer';
import MenuIcon from "@mui/icons-material/Menu";
import CssBaseline from "@mui/material/CssBaseline";

import Box from "@mui/material/Box";
import { css } from "@emotion/react";

import { UserContext } from "../../App";
import { mainListItems } from "../dashboard/listItems";

//import { Auth } from '@aws-amplify/auth';
import { signOut } from 'aws-amplify/auth';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" css={copyright}>
      {"Copyright © "}
      Teraoka Seiko Co., Ltd. All Rights Reserved. {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

const drawerWidth: number = 240;

const MuiDrawer = styled(Drawer, {
  shouldForwardProp: (prop) => prop !== "open",
})<React.PropsWithChildren<{ open: boolean }>>(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: 0,
    }),
  },
}));

type WrapperProps = {
  children: React.ReactElement;
};

const Wrapper: React.FC<WrapperProps> = ({ children }) => {
  const { setCurrentUser } = useContext(UserContext);
  const { currentUser } = useContext(UserContext);
  const [open, setOpen] = React.useState(true);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  // サインアウトボタンを設置
  const signout = () => {
    signOut().catch((err: any) => console.log(err));
    setCurrentUser(null);
  };

  const userInfo = () => {
    console.log(JSON.stringify(currentUser))
    if (currentUser) {
      return (
        currentUser.attributes.email +
        "(" +
        currentUser.attributes["custom:department"] +
        ")"
      );
    }
    return "Signing in ...";
  };

  return (
    <div css={main}>
      <CssBaseline />
      <AppBar position="relative">
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            css={buttonStyle}
            onClick={toggleDrawer}
          >
            <MenuIcon />
          </IconButton>
          <Typography component={Link} to="/" variant="h6" css={titleStyle}>
            {userInfo()}
          </Typography>
          <Button onClick={signout} color="inherit">
            Sign Out
          </Button>
        </Toolbar>
      </AppBar>
      <Box sx={{ display: "flex", height: 'calc(100vh - 64px)' }}>
        <MuiDrawer variant="permanent" open={open}>
          <Box
            sx={{
              overflow: "auto",
              height: "100vh",
              display: "flex",
            }}
          >
            <List component="nav">{mainListItems}</List>
          </Box>
        </MuiDrawer>
        <div css={style}>
          <Box sx={{ width: '100%',height: 'calc(100vh - 64px)' }}>
            <Grid container direction="row" justifyContent="center">
              <Grid item sx={{ width: '100%' }}>{children}</Grid>
            </Grid>
          </Box>
        </div>
      </Box>
      <Copyright />
    </div>
  );
}

export default Wrapper;

const style = css`
    width: 100%;
`;

const buttonStyle = css`
  marginright: theme.spacing(2);
`;

const titleStyle = css`
  flex-grow: 1;
  text-decoration: none;
  color: inherit;
`;

const copyright = css`
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #f0f0f0; /* 背景色 */
  padding: 10px;
  text-align: center;
`;

const main = css`
  height: 100%;
  margin: 0;
`;